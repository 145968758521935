export default {
    // baseURL: "http://localhost:8080/", // local
    baseURL: "https://sharelink-development.herokuapp.com/", // development


    /**
     * DEIXAR DESCOMENTADO QUANDO SUBIR PRA PRODUÇÃO (AMBIENTE FINAL)
     */
    // baseURL: "https://api-sharelink.herokuapp.com/", // produção
};
